import axios, {AxiosError, AxiosResponse} from 'axios';

import makeAdminRequest from '../../adminRequest';

import MessageResponse from '../../../types/api/MessageResponse';
import Localization, {IEditLocalization, IPlatform} from '../../../types/api/Game/Localization';
import config from '../../config';
import {AUTH_SESSION_NAME, BANK_SESSION_NAME} from '../../../config';
import error from '../../errors';

export async function getLocalizations(): Promise<AxiosResponse<Localization[]>> {
  return makeAdminRequest('GET', '/api/admin/game/localizations');
}

export async function getPlatforms(): Promise<AxiosResponse<IPlatform[]>> {
  return makeAdminRequest('GET', '/api/admin/game/localizations/platforms');
}

export async function createLocalization({name, shortcut}: IEditLocalization): Promise<AxiosResponse<MessageResponse>> {
  return makeAdminRequest('POST', '/api/admin/game/localizations', {name, shortcut});
}

export async function updateLocalization({id, name, shortcut}: IEditLocalization): Promise<AxiosResponse<MessageResponse>> {
  return makeAdminRequest('POST', `/api/admin/game/localizations/${id}`, {name, shortcut});
}

export async function deleteLocalization(id: string) {
  return makeAdminRequest('DELETE', `/api/admin/game/localizations/${id}`);
}

export async function uploadFile(
  id: string,
  platform: string,
  formData: FormData,
  bank: boolean = false,
) {
  try {
    return await axios({
      method: 'post',
      url: `${config.SERVER_PROTO}://${config.SERVER_URL}/api/admin/game/localizations/${id}/platforms/${platform}/localization-file`,
      data: formData,
      withCredentials: true,
      xsrfHeaderName: 'authorization',
      xsrfCookieName: bank ? BANK_SESSION_NAME : AUTH_SESSION_NAME,
    });
  } catch (err: AxiosError | any) {
    if (err.isAxiosError) {
      error(err);
    }
    throw err;
  }
}

export async function deleteLocalizationFile(id: string, platform: string) {
  return makeAdminRequest('DELETE', `/api/admin/game/localizations/${id}/platforms/${platform}/localization-file`);
}
