import {AxiosResponse} from 'axios';

import makeAdminRequest from '../../adminRequest';

import MessageResponse from '../../../types/api/MessageResponse';
import PrizePoolController, {
  ILastPrizePool,
  IPrizePoolControllerEdit
} from '../../../types/api/Banking/PrizePoolController';
import ItemTypes from '../../../types/api/Admin/ItemTypes';

export async function getPrizePoolController(): Promise<AxiosResponse<PrizePoolController>> {
  return makeAdminRequest('GET', '/api/banking/admin/prize-pool-controller');
}

export async function setPrizePoolController({
  prizePoolPeriodHours, prizePoolVestedAmount, prizePoolsEnabled
}: IPrizePoolControllerEdit): Promise<AxiosResponse<MessageResponse>> {
  return makeAdminRequest('POST', '/api/banking/admin/prize-pool-controller', {
    prizePoolPeriodHours, prizePoolVestedAmount, prizePoolsEnabled
  });
}

export async function getLastPrizePools(): Promise<AxiosResponse<ILastPrizePool[]>> {
  return makeAdminRequest('GET', '/api/banking/admin/prize-pool-controller/last-pools');
}

export async function getPrizePoolTypes(): Promise<AxiosResponse<ItemTypes[]>> {
  return makeAdminRequest('GET', '/api/banking/admin/prize-pool-controller/types');
}
