import {AxiosResponse} from 'axios';

import makeAdminRequest from '../../adminRequest';

import PaginatedResponse from '../../../types/api/PaginatedResponse';
import Perk, {EditPerk, PerkValues, RarityAcquisition} from '../../../types/api/Game/Perk';
import IInventoryCategories from '../../../types/api/Admin/Game/InventoryCategories';
import MessageResponse from '../../../types/api/MessageResponse';
import IDropdownResults from '../../../types/api/Admin/GameSessions/DropdownResults';
import {CompBulkActions} from '../../../types/api/Game/GameplayTask';
import IDropdownResultsByName from '../../../types/api/Admin/ReferralRewards/DropdownResults';

export async function getFilterPerks(
  page: number = 1, perPage: number = 20, {name, type, categories, inventoryClassIds}: PerkValues
): Promise<AxiosResponse<PaginatedResponse<Perk>>> {
  return makeAdminRequest('POST', '/api/admin/game/perks/filter', {
    page, perPage, name, type: type?.id ?? undefined, categories, inventoryClassIds,
  });
}

export async function getRarityAcquisitions(type: number): Promise<AxiosResponse<RarityAcquisition[]>> {
  return makeAdminRequest('GET', `/api/admin/game/rarity-acquisitions?perkType=${type}`);
}

export async function getRarityAcquisitionTypes(): Promise<AxiosResponse<IInventoryCategories[]>> {
  return makeAdminRequest('GET', '/api/admin/game/rarity-acquisitions/types');
}

export async function deleteRarity(id: string, perkType: number): Promise<AxiosResponse<MessageResponse>> {
  return makeAdminRequest('DELETE', `/api/admin/game/rarity-acquisitions/${id}`, {perkType});
}

export async function getRarityLevels(): Promise<AxiosResponse<IInventoryCategories[]>> {
  return makeAdminRequest('GET', '/api/admin/game/perks/levels');
}

export async function createRarityAcquisitions(rarity: number, type: number, perkType: number, probabilities?: number[][], boostedProbabilities?: number[][]): Promise<AxiosResponse<MessageResponse>> {
  return makeAdminRequest('POST', '/api/admin/game/rarity-acquisitions', {
    rarity, type, perkType, probabilities, boostedProbabilities
  });
}

export async function updateRarityAcquisitions(id: string, rarity: number, type: number, perkType: number, probabilities?: number[][], boostedProbabilities?: number[][]): Promise<AxiosResponse<MessageResponse>> {
  return makeAdminRequest('POST', `/api/admin/game/rarity-acquisitions/${id}`, {
    rarity, type, perkType, probabilities, boostedProbabilities
  });
}

export async function createPerk({
  type, category, characterAttributes,
  name, description, weight, currency,
  limitationCharacterAttributeId, rarityPoints
}: EditPerk): Promise<AxiosResponse<MessageResponse>> {
  return makeAdminRequest('POST', '/api/admin/game/perks', {
    type: type.value, category: category.value,
    characterAttributes, name, description, weight, currency,
    limitationCharacterAttributeId, rarityPoints
  });
}

export async function updatePerk(id: string, {
  type, category, characterAttributes,
  name, description, weight, currency,
  limitationCharacterAttributeId, rarityPoints
}: EditPerk): Promise<AxiosResponse<MessageResponse>> {
  return makeAdminRequest('POST', `/api/admin/game/perks/${id}`, {
    type: type.value, category: category.value,
    characterAttributes, name, description, weight, currency,
    limitationCharacterAttributeId, rarityPoints
  });
}

export async function deletePerk(id: string) {
  return makeAdminRequest('DELETE', `/api/admin/game/perks/${id}`);
}

export async function searchBalanceParametersByName(name: string): Promise<AxiosResponse<IDropdownResults[]>> {
  return await makeAdminRequest('GET', `/api/admin/game/balance/parameters/search?name=${name}`);
}

export async function getPerkTypes() {
  return makeAdminRequest('GET', '/api/admin/game/perks/types');
}

export async function savePerkCompatibilities(
  perkId: string, compatibilities: { inventoryClassId: string, compatible: boolean }[]
) {
  return makeAdminRequest('POST', `/api/admin/game/perks/${perkId}/compatibilities`, {compatibilities});
}

export async function getInventoryCategories(): Promise<AxiosResponse<IInventoryCategories[]>> {
  return makeAdminRequest('GET', '/api/admin/game/perks/inventory-categories');
}

export async function getCompBulkActions(): Promise<AxiosResponse<IInventoryCategories[]>> {
  return makeAdminRequest('GET', '/api/admin/game/perks/compatibilities-bulk-actions');
}

export async function saveCompatibilitiesBulkActions({
  action, ids, compatibilities, repCompatibilities, withComp
}: CompBulkActions,) {
  return makeAdminRequest('POST', '/api/admin/game/perks/compatibilities-bulk-actions', {
    action, perkIds: ids,
    compatibilities: compatibilities ?
      compatibilities.map(item => ({
        inventoryClassId: item.inventoryClassId?.value,
        compatible: withComp ? item?.compatible : undefined
      })) :
      repCompatibilities ? repCompatibilities.map(item => ({
        inventoryClassId: item.inventoryClassId?.value,
        replaceInventoryClassId: item.replaceInventoryClassId?.value,
        compatible: item.compatible,
      })) : undefined,
  });
}

export async function searchPerksByName(name: string): Promise<AxiosResponse<IDropdownResultsByName[]>> {
  return makeAdminRequest('GET', `/api/admin/game/perks/search?name=${name}`);
}

export async function syncRarityPoints(perkId: string) {
  return makeAdminRequest('POST', `/api/admin/game/perks/${perkId}/sync-rarity-points`);
}