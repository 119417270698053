import API from '../api/index';
import {
  AccountId,
  ConnectionUrl,
  FcmToken,
  LOCAL_STORAGE_NAMES,
  LocalStorageTypes,
  PeriodSetting,
  PeriodSettings,
  roleNames,
  Session,
  SessionVal,
  Wallets,
} from './types';

export function setItem(
  name: LOCAL_STORAGE_NAMES,
  item: LocalStorageTypes,
): void {
  localStorage.setItem(name, JSON.stringify(item));
}

export function getItem(
  name: LOCAL_STORAGE_NAMES,
): LocalStorageTypes | undefined {
  const item = localStorage.getItem(name);
  if (item) {
    try {
      return JSON.parse(item);
    } catch (err) {
      return item;
    }
  }
}

export function removeItem(
  name: LOCAL_STORAGE_NAMES,
): void {
  localStorage.removeItem(name);
}

export function cleanItems(): void {
  localStorage.removeItem(LOCAL_STORAGE_NAMES.ACCOUNT_ID);
  localStorage.removeItem(LOCAL_STORAGE_NAMES.ROLE_NAMES);
  localStorage.removeItem(LOCAL_STORAGE_NAMES.WALLETS);
  localStorage.removeItem(LOCAL_STORAGE_NAMES.PERIOD_SETTINGS);
  localStorage.removeItem(LOCAL_STORAGE_NAMES.CONNECTION_URL);
  localStorage.removeItem(LOCAL_STORAGE_NAMES.SESSION);
  localStorage.removeItem(LOCAL_STORAGE_NAMES.UPDATE_EMAIL_PATH);
  localStorage.removeItem(LOCAL_STORAGE_NAMES.FCM_TOKEN);
  localStorage.removeItem(LOCAL_STORAGE_NAMES.SEND_TO_CLAIM_PATH);
  localStorage.removeItem(LOCAL_STORAGE_NAMES.TRIGGER_ID);
  localStorage.removeItem(LOCAL_STORAGE_NAMES.DISCORD_CONNECTION_PAGE);
  localStorage.removeItem(LOCAL_STORAGE_NAMES.OPEN_LOOT_CONNECTION_PAGE);
  localStorage.removeItem(LOCAL_STORAGE_NAMES.OPEN_LOOT_USER_ID);
  localStorage.removeItem(LOCAL_STORAGE_NAMES.COMMON_MISSIONS);
  localStorage.removeItem(LOCAL_STORAGE_NAMES.SPECIAL_MISSIONS);
  localStorage.removeItem(LOCAL_STORAGE_NAMES.ACHIEVEMENTS);
  localStorage.removeItem(LOCAL_STORAGE_NAMES.LEVEL_REWARDS);
}

export function setItems(
  accountId?: AccountId,
  roles?: roleNames,
  wallets?: Wallets,
  connectionUrl?: ConnectionUrl,
  sessionVal?: SessionVal,
  session?: Session,
  fcmToken?: FcmToken,
): void {
  if (session) {
    setItem(LOCAL_STORAGE_NAMES.SESSION, session);
  }
  if (fcmToken) {
    setItem(LOCAL_STORAGE_NAMES.FCM_TOKEN, fcmToken);
  }
  if (sessionVal) {
    setItem(LOCAL_STORAGE_NAMES.SESSION_VAL, sessionVal);
  }
  if (connectionUrl) {
    setItem(LOCAL_STORAGE_NAMES.CONNECTION_URL, connectionUrl);
  }
  if (accountId) {
    setItem(LOCAL_STORAGE_NAMES.ACCOUNT_ID, accountId);
  }
  if (roles && roles.length) {
    setItem(LOCAL_STORAGE_NAMES.ROLE_NAMES, roles);
  }
  if (wallets && wallets.length) {
    setItem(LOCAL_STORAGE_NAMES.WALLETS, wallets);
  }
}

export function getPeriodSetting(collectionId: string): PeriodSetting | void {
  const periodSettings = getItem(
    LOCAL_STORAGE_NAMES.PERIOD_SETTINGS,
  ) as PeriodSettings;
  if (!periodSettings) {
    return;
  }
  const thisCollectionSettings = periodSettings.find(
    (settings) => settings.collectionId === collectionId,
  );
  if (!thisCollectionSettings) {
    return;
  }

  return thisCollectionSettings;
}

export function addPeriodSetting(
  collectionId: string,
  pollingPath: string,
): void {
  const periodSettings = getItem(
    LOCAL_STORAGE_NAMES.PERIOD_SETTINGS,
  ) as PeriodSettings;
  if (!periodSettings) {
    setItem(LOCAL_STORAGE_NAMES.PERIOD_SETTINGS, [
      {collectionId, pollingPath: pollingPath},
    ]);
  } else {
    if (
      periodSettings.find((settings) => settings.collectionId === collectionId)
    ) {
      console.error('Duplication of period settings for the same collection');
    }
    periodSettings.push({collectionId, pollingPath: pollingPath});
    setItem(LOCAL_STORAGE_NAMES.PERIOD_SETTINGS, periodSettings);
  }
}

export function removePeriodSettings(collectionId: string): void {
  const periodSettings = getItem(
    LOCAL_STORAGE_NAMES.PERIOD_SETTINGS,
  ) as PeriodSettings;
  if (!periodSettings) {
    return;
  }
  setItem(
    LOCAL_STORAGE_NAMES.PERIOD_SETTINGS,
    periodSettings.filter((setting) => setting.collectionId !== collectionId),
  );
}

export async function getAccountId(
  refresh: boolean = true,
): Promise<AccountId | void> {
  let accountId: any = getItem(LOCAL_STORAGE_NAMES.ACCOUNT_ID);
  if (!accountId) {
    if (!refresh) {
      return;
    }
    accountId = await API.users
      .refreshLSData(LOCAL_STORAGE_NAMES.ACCOUNT_ID)
      .catch();
    if (!accountId) {
      return;
    }
  }
  return accountId;
}

export function getRolesSync(): roleNames | void {
  let roles: any = getItem(LOCAL_STORAGE_NAMES.ROLE_NAMES);
  if (!roles) {
    return;
  }
  return roles as roleNames;
}

export function getSessionVal(): SessionVal | void {
  let session: any = getItem(LOCAL_STORAGE_NAMES.SESSION_VAL);
  if (!session) {
    return;
  }
  return session as SessionVal;
}

export function getSession(): Session | void {
  let session: any = getItem(LOCAL_STORAGE_NAMES.SESSION);
  if (!session) {
    return;
  }
  return session as Session;
}

export function getFcmToken(): FcmToken | void {
  let fcmToken: LocalStorageTypes | undefined = getItem(LOCAL_STORAGE_NAMES.FCM_TOKEN);
  if (!fcmToken) {
    return;
  }
  return fcmToken as FcmToken;
}

export async function getConnectionUrl(refresh: boolean = true) {
  let connectionUrl: ConnectionUrl | undefined = getItem(
    LOCAL_STORAGE_NAMES.CONNECTION_URL,
  ) as ConnectionUrl;
  if (!connectionUrl) {
    if (!refresh) {
      return;
    }
    const connectionUrlResponse = await API.wallets.getConnectionUrl().catch();
    connectionUrl = connectionUrlResponse?.data?.url;
    if (!connectionUrl) {
      return;
    }
    await setItem(LOCAL_STORAGE_NAMES.CONNECTION_URL, connectionUrl);
  }
  return connectionUrl;
}