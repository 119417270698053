import React, {CSSProperties} from 'react';
import {CButton} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import {
  cilPencil, cilPlus, cilTrash, cilZoom, cilReload,
  cilCloudDownload, cilPeople, cilCopy, cilBlurLinear,
  cilImage, cilSettings, cilGift, cilMinus,
} from '@coreui/icons';

import Tooltip, {TooltipDirection} from '../../common/Tooltip/Tooltip';

import BulkAction from '../../../assets/images/common/ActionButton/BulkAction.svg';
import Kinds from '../../../assets/images/common/ActionButton/Kinds.svg';
import Classes from '../../../assets/images/common/ActionButton/Classes.svg';
import Compatibilities from '../../../assets/images/common/ActionButton/Compatibilities.svg';
import Grant from '../../../assets/images/common/ActionButton/Grant.svg';
import ForceDisable from '../../../assets/images/common/ActionButton/ForceDisable.svg';
import Play from '../../../assets/images/common/ActionButton/Play.svg';
import SaveAll from '../../../assets/images/common/ActionButton/SaveAll.svg';

import {Wrapper} from './styles';

interface IActionButton {
    onClick: ((event: any) => void) | undefined;
    buttonText?: string;
    noteText?: string;
    color?: number;
    icon?: number;
    wrapperStyle?: CSSProperties;
    buttonStyle?: CSSProperties;
    iconStyle?: CSSProperties;
    tooltipStyle?: CSSProperties;
    tooltipElementStyle?: CSSProperties;
    marginBottom?: number;
    disabled?: boolean;
    tooltipDirection?: TooltipDirection;
    isDisabledTooltip?: boolean;
    zIndex?: number
}

const ActionButton = ({
  onClick, buttonText, noteText, color = 0, icon = 0,
  iconStyle = {width: '16px', height: '16px'}, wrapperStyle,
  buttonStyle, marginBottom, disabled, tooltipElementStyle,
  tooltipDirection = TooltipDirection.Left,
  isDisabledTooltip = false, zIndex = 1, tooltipStyle,
}: IActionButton) => {
  const chooseColor = (color: number): string => {
    switch (color) {
    case 0:
      return 'warning'
    case 1:
      return 'danger';
    case 2:
      return 'info';
    case 3:
      return 'success';
    default:
      return 'warning';
    }
  };

  const chooseIcon = (icon: number): string[] | string => {
    switch (icon) {
    case 0:
      return cilPencil;
    case 1:
      return cilTrash;
    case 2:
      return cilZoom;
    case 3:
      return cilPlus;
    case 4:
      return cilReload;
    case 5:
      return cilCloudDownload;
    case 6:
      return cilPeople;
    case 7:
      return Grant;
    case 8:
      return cilBlurLinear;
    case 9:
      return cilCopy;
    case 10:
      return BulkAction;
    case 11:
      return Kinds;
    case 12:
      return Classes;
    case 13:
      return Compatibilities;
    case 14:
      return ForceDisable;
    case 15:
      return Play;
    case 16:
      return SaveAll;
    case 17:
      return cilImage;
    case 18:
      return cilSettings;
    case 19:
      return cilGift;
    case 20:
      return cilMinus;
    default:
      return cilPencil;
    }
  };

  return (
    <Wrapper marginBottom={marginBottom} style={wrapperStyle} zIndex={zIndex}>
      <Tooltip
        text={noteText}
        direction={tooltipDirection}
        isDisabled={isDisabledTooltip || !noteText}
        tooltipStyle={{width: 'auto', ...tooltipStyle}}
        tooltipElementStyle={tooltipElementStyle}
        hideOnContentHover={true}
      >
        <CButton
          onClick={onClick}
          color={chooseColor(color)}
          style={buttonStyle}
          size="sm"
          disabled={disabled}
        >
          {buttonText ?
            <span style={{fontSize: 'large', fontFamily: 'AGP Medium'}}>{buttonText}</span> : (
              Array.isArray(chooseIcon(icon)) ?
                <CIcon icon={chooseIcon(icon)} style={iconStyle}/> :
                <img src={chooseIcon(icon) as string} style={iconStyle}
                  alt='Icon'/>
            )}
        </CButton>
      </Tooltip>
    </Wrapper>
  );
};

export default ActionButton;