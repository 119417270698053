import React, {ChangeEvent, CSSProperties, useEffect, useRef} from 'react';
import {CFormInput} from '@coreui/react';

interface ITextOrNumberInput {
    value: string | number | undefined;
    onChange: (e: ChangeEvent<HTMLInputElement> | any) => void;
    type?: string;
    placeholder?: string;
    min?: number;
    max?: number;
    disabled?: boolean;
    style?: CSSProperties;
    activeFocus?: boolean;
    maxLength?: number;
}

const TextOrNumberInput = ({
  value,
  onChange,
  type = 'text',
  placeholder = '',
  min,
  max,
  disabled,
  style,
  activeFocus = false,
  maxLength,
}: ITextOrNumberInput) => {
  const selectRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (selectRef.current && activeFocus) {
      selectRef.current.focus();
    }
  }, [activeFocus]);

  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      if (selectRef.current && document.activeElement === selectRef.current) {
        e.preventDefault(); // Блокируем прокрутку
      }
    };

    const inputRef = selectRef.current;
    inputRef?.addEventListener('wheel', handleWheel);

    return () => {
      inputRef?.removeEventListener('wheel', handleWheel);
    };
  }, []);

  return (
    <CFormInput
      value={value}
      onChange={onChange}
      type={type}
      placeholder={placeholder}
      min={min}
      max={max}
      disabled={disabled}
      style={style}
      ref={selectRef}
      maxLength={maxLength}
    />
  );
};

export default TextOrNumberInput;